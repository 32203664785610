/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "Desde hace ya un tiempo estoy modificando el estilo del blog, tenía una idea en mente, convertir mi blog en una pizarra, no partía desde cero, era ya una idea vieja que venía madurando en forma de un ", React.createElement(_components.a, {
    href: "http://litox.entramado.net/2009/07/16/cambio-de-look/",
    title: "Cambio de look"
  }, "cambio de mentalidad que ahorra energía"), " y cansa menos la vista, además pienso que un blog está muy bien representado por una pizarra, un sitio donde se van escribiendo cosas, donde vas aprendiendo y aclarando conceptos, pero con una ventaja, la información no se pierde, cuando escribes algo nuevo siempre tienes sitio en esta pizarra mágica, el borrador es algo voluntario, consecuencia de la ", React.createElement(_components.a, {
    href: "http://lasindias.net/indianopedia/L%C3%B3gica_de_la_abundancia",
    title: "Lógica de la abundancia"
  }, "lógica de la abundancia"), " que es posible en la red."), "\n", React.createElement(_components.p, null, "Espero que os guste el cambio."), "\n", React.createElement(_components.p, null, "El fondo y los distintos elementos que componen esta personalización los he conseguido hacer en Gimp, ayudado por el siguiente tutorial e imaginación."), "\n", React.createElement(_components.p, null, "[youtube=http://www.youtube.com/watch?v=QRC-PfZf8v4]"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
